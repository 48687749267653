
<div mat-dialog-title class="!m-0 w-full block before:!hidden">
  <h3 class="text-primary md:text-3xl text-2xl m-0 font-bold font__title py-4 border-b border-b-gray-300">
      Términos y condiciones
  </h3>
</div>
<div mat-dialog-content class="w-full !p-0">
  <div class="p-6 ">
    <span class="text-md" [innerHTML]="getTerms()"></span>
  </div>
</div>
<div mat-dialog-actions class="!m-0 w-full block">
  <div class="w-full flex flex-wrap justify-between actions_modal">
    <div class="block"></div>
    <div class="flex flex-wrap justify-between gap-2">
      <button-cy type="button" (onPress)="dismiss()" addClass="btn__primary">Cerrar</button-cy>
    </div>
  </div>
</div>