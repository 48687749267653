import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { XtrasService } from '../../services/xtras.service';
import { UserModel } from '../../services/user-model';
import { CommonModule } from '@angular/common';
import { ButtonCapYeiComponent } from '../../components/button/button.component';
import { Router } from '@angular/router';
import { SettingsProvider } from '../../services/settings';
import { HttpClientProvider } from '../../services/http-client';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from '../../pipes/filter';
import { SearchDirective } from '../../directives/search.directive';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../interfaces/interfaces';
import { getEnvironment } from '../../environment/environment';
import { CustomSubjectsService } from '../../services/custom-subjects.service';

@Component({
  selector: 'modal-register-profile',
  templateUrl: './register-profile.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    ButtonCapYeiComponent,
    FilterPipe,
    SearchDirective,
    MatDialogModule,
  ]
})
export class RegisterProfileModal implements OnInit, OnDestroy {

  env = getEnvironment();

  dataForm:FormGroup;

  filterExpeditionCI:string = '';
  expeditionData:{id:number|string, name:string}[] = [];

  loadingCities:boolean = false;
  filterCities: string = '';
  countries:any[] = [];

  loadingCountries:boolean = false;
  filterCountries: string = '';
  cities:any[] = [];

  filterGenders: string = '';
  genders = [
    { id: 'male', name: 'Masculino' },
    { id: 'female', name: 'Femenino' },
    // { id: 'otro', name: 'Otro' },
  ];

  loadingSizes:boolean = false;
  filterSizes: string = '';
  sizes:any[] = [];

  // Wilstermann
  type_wilster:boolean = false;
  type_oriente:boolean = false;

  

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    public userModel: UserModel,
    public xServices: XtrasService,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private alert: AlertService,
    private authService: AuthService,
    public cSService: CustomSubjectsService,
    public dialogRef: MatDialogRef<RegisterProfileModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    this.credentialData();
    if(this.env.type == 'wilstermann') {
      this.dataForm = new FormGroup({
        ci_number:            new FormControl(null as any, [Validators.required]),
        ci_extension:         new FormControl(null as any),
        ci_expedition_basic:  new FormControl(null as any, [Validators.required]),
    
        first_name:           new FormControl(null as any, [Validators.required]),
        last_name:            new FormControl(null as any, [Validators.required]),
        email:                new FormControl(null as any, [Validators.required]),
        phone_number:         new FormControl(null as any, [Validators.required]),
        birthdate:            new FormControl(null as any, [Validators.required]),
        gender:               new FormControl(null as any, [Validators.required]),
    
        invoice_name:         new FormControl(null as any,),
        invoice_nit:          new FormControl(null as any,),
    
        country_id:           new FormControl(1 as any, [Validators.required]),
        city_id:              new FormControl(null as any, [Validators.required]),
        address:              new FormControl(null as any, [Validators.required]),

        type_wilster:         new FormControl(false),
    
        // shirt_size:           new FormControl(null as any, [Validators.required]),
      });
    } else {
      this.dataForm = new FormGroup({
        ci_number:            new FormControl(null as any, [Validators.required]),
        ci_extension:         new FormControl(null as any),
        ci_expedition_basic:  new FormControl(null as any, [Validators.required]),
    
        first_name:           new FormControl(null as any, [Validators.required]),
        last_name:            new FormControl(null as any, [Validators.required]),
        email:                new FormControl(null as any, [Validators.required]),
        phone_number:         new FormControl(null as any, [Validators.required]),
        birthdate:            new FormControl(null as any, [Validators.required]),
        gender:               new FormControl(null as any, [Validators.required]),
    
        invoice_name:         new FormControl(null as any,),
        invoice_nit:          new FormControl(null as any,),
    
        country_id:           new FormControl(1 as any, [Validators.required]),
        city_id:              new FormControl(null as any, [Validators.required]),
        address:              new FormControl(null as any, [Validators.required]),
      });
      if(this.env.type == 'oriente-petrolero') {
        this.dataForm.addControl('image',           new FormControl(null as any, [Validators.required]));
        this.dataForm.addControl('ci_image_front',  new FormControl(null as any,));
        this.dataForm.addControl('ci_image_back',   new FormControl(null as any,));
      } else if(this.env.country === 'cl') {
        this.dataForm.get('ci_extension')?.removeValidators(Validators.required);
        this.dataForm.get('ci_extension')?.updateValueAndValidity();
        this.dataForm.get('ci_expedition_basic')?.removeValidators(Validators.required);
        this.dataForm.get('ci_expedition_basic')?.updateValueAndValidity();
      }
    }

    this.cityByCountry(1);
    this.dataForm.get('country_id')?.valueChanges.subscribe(data => {
      if(data) {
        this.cityByCountry(data);
      }
    });
    this.initializeApp();
    console.log(this.authService.getUser());
    if(this.authService.getUser()) {
      this.dataForm.patchValue({
        'ci_number':            this.authService.getUser()?.ci_number ?? null,
        'ci_expedition_basic':  this.authService.getUser()?.ci_expedition_basic ?? null,
        'first_name':           this.authService.getUser()?.name ?? null,
        'email':                this.authService.getUser()?.email ?? null,
        'birthdate':            this.authService.getUser()?.birthdate ?? null,
      });
    }
  }

  credentialData() {
    this.expeditionData = this.xServices.getCountryCredentialExpedition();
  }

  initializeApp() {
    this.loadingCountries = true;
    this.http.getRequest(SettingsProvider.getUrl('countries')).subscribe({
      next:(result:any) => {
        if(result['status']&&result['data']) {
          this.loadingCountries = false;
          this.countries = result.data?.countries ?? [];
        } else {
          this.loadingCountries = false;
        }
      },
      error: (error: any) => {
        this.loadingCountries = false;
      }
    });
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  cityByCountry(id: any) {
    this.loadingCities = true;
    this.cities = [];
    this.http.getRequest(SettingsProvider.getUrl(`cities-by-country/${id}`)).subscribe({
      next:(result:any) => {
        if(result['status']&&result['data']) {
          this.loadingCities = false;
          this.cities = result.data?.cities ?? [];
        } else {
          this.loadingCities = false;
        }
      },
      error: (error: any) => {
        this.loadingCities = false;
      }
    });
  }

  ngAfterViewInit(){
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  async submit() {
    let formData;
    if(this.env.type == 'oriente-petrolero' && this.imagePreview) {
      formData = await this.xServices.getFormData(this.dataForm.value);
      // formData.set('image', this.imagePreview);
    } else {
      formData = this.dataForm.value;
    }
    this.load.loadingShow();
    this.http.postRequest(SettingsProvider.getUrl('membership'), formData).subscribe({
      next:(result:any) => {
        if(result['status']) {
          this.load.dismiss();
          this.authService.getUserByToken().subscribe();
          this.cSService.changeProfileValue = true;
          this.dismiss();
          this.alert.alertCapYei({
            type: 'success',
            message: '¡Éxito!',
            description: result['message'] ?? 'Perfil creado correctamente',
            buttons: {
              cancel: { show: false },
            }
          });
        } else {
          this.load.dismiss();
          this.xServices.getErrorApi(result, true, '/');
        }
      },
      error: (error: any) => {
        this.load.dismiss();
        this.toast.error('¡Error!', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      }
    });
  }

  typeWilster(event:any) {
    if(event) {
      this.dataForm = new FormGroup({
        ci_number:            new FormControl(null as any, [Validators.required]),
        ci_extension:         new FormControl(null as any),
        ci_expedition_basic:  new FormControl(null as any, [Validators.required]),
    
        first_name:           new FormControl(null as any, [Validators.required]),
        last_name:            new FormControl(null as any, [Validators.required]),
        email:                new FormControl('bebe@wilster.com'),
        phone_number:         new FormControl('77777777'),
        birthdate:            new FormControl(null as any, [Validators.required]),
        gender:               new FormControl('otro'),
    
        invoice_name:         new FormControl(null as any,),
        invoice_nit:          new FormControl(null as any,),
    
        country_id:           new FormControl(1),
        city_id:              new FormControl(1),
        address:              new FormControl('Aviadores desde la cuna'),

        type_wilster:         new FormControl(true),
      });
    } else {
      this.dataForm = new FormGroup({
        ci_number:            new FormControl(null as any, [Validators.required]),
        ci_extension:         new FormControl(null as any),
        ci_expedition_basic:  new FormControl(null as any, [Validators.required]),
    
        first_name:           new FormControl(null as any, [Validators.required]),
        last_name:            new FormControl(null as any, [Validators.required]),
        email:                new FormControl(null as any, [Validators.required]),
        phone_number:         new FormControl(null as any, [Validators.required]),
        birthdate:            new FormControl(null as any, [Validators.required]),
        gender:               new FormControl(null as any, [Validators.required]),
    
        invoice_name:         new FormControl(null as any,),
        invoice_nit:          new FormControl(null as any,),
    
        country_id:           new FormControl(1 as any, [Validators.required]),
        city_id:              new FormControl(null as any, [Validators.required]),
        address:              new FormControl(null as any, [Validators.required]),

        type_wilster:         new FormControl(false),
    
        // shirt_size:           new FormControl(null as any, [Validators.required]),
      });
    }
  }

  typeOriente(event:any) {
    console.log(event, "****")
    if(this.type_oriente === false){
      this.dataForm.get('ci_image_front')?.patchValue(null);
      this.dataForm.get('ci_image_back')?.patchValue(null);
      //this.showPreviewDoc1 = ''
      //this.showPreviewDoc2
    }
  }

  imagePreview?: string;
  imagePreviewDoc1?:string;
  imagePreviewDoc2?:string;

  showPreview(event: any) {
    const file = event.target.files[0];
    this.dataForm.get('image')?.patchValue(file);
    this.dataForm.get('image')?.updateValueAndValidity();
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  showPreviewDoc1(event: any) {
    //console.log(this.dataForm.value);
    const fileDoc1 = event.target.files[0];
    this.dataForm.get('ci_image_front')?.patchValue(fileDoc1);
    this.dataForm.get('ci_image_front')?.updateValueAndValidity();
    // File Preview
    const readerdoc1 = new FileReader();
    readerdoc1.onload = () => {
      this.imagePreviewDoc1 = readerdoc1.result as string;
    }
    readerdoc1.readAsDataURL(fileDoc1)
  }

  showPreviewDoc2(event: any) {
    const fileDoc2 = event.target.files[0];
    //console.log(file)
    this.dataForm.get('ci_image_back')?.patchValue(fileDoc2);
    this.dataForm.get('ci_image_back')?.updateValueAndValidity();
    // File Preview
    const readerdoc2 = new FileReader();
    readerdoc2.onload = () => {
      this.imagePreviewDoc2 = readerdoc2.result as string;
    }
    readerdoc2.readAsDataURL(fileDoc2)
  }

}
